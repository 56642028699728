export default class AnimationScrollPage {
  constructor() {
    this.$el = $('.animation')
    this.winH = window.innerHeight
    this.winW = window.innerWidth
    this.offset = window.innerHeight
    this.WindowScrollTop = null
    this.topCurrent = null
    this.addClassAni = 'set-animation'
  }

  init() {
    this.animationEle()
    $(window).on('scroll resize', () => {
      this.animationEle()
    })
  }

  formatNumber (text, min, max) {
    return text.toLocaleString('en-US', {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })
  }

  animationNumber($el) {
    const numberCount = parseFloat($el.attr('data-numbercounter'))
    $el.addClass('counted')
    $el.prop('Counter', 0).animate({
      Counter: numberCount,
    }, {
      duration: 4000,
      easing: 'swing',
      step: (now) => {
        $el.text(this.formatNumber(Math.floor(now), 0, 2))
      },
      complete: () => {
        $el.text(this.formatNumber(numberCount, 0, 2))
      },
    })
  }

  animationEle() {
    this.offset = this.winH
    if ($('body').hasClass('has-animation')) {
      this.WindowScrollTop = $(window).scrollTop()
      this.$el.each((_index, ele) => {
        const $elm = $(ele)
        if ($elm.hasClass(this.addClassAni)) {
          return true
        }
        this.topCurrent = $elm.offset().top
        if (this.WindowScrollTop > this.topCurrent - this.offset) {
          $elm.addClass(this.addClassAni)
          if ($elm.hasClass('number-counter') && !$elm.hasClass('counted')){
            this.animationNumber($elm)
          }
        }
        return true
      })
    }
  }
}

new AnimationScrollPage().init()
