export default class Menu {
  constructor() {
    this.this = '#main-menu'
    this.$this = $(this.this)
    this.$mainMenu = $('#main-menu').find('.main-menu-ul')
    this.elementItem = '.hamburger-menu, html, #main-menu, #header'
    this.$header = $('#header, #main-menu-mobile')
    this.$hamburgerMenu = $('.hamburger-menu')
    this.li = this.$this.find('.main-menu-ul >li>div')
    this.liLevel1 = this.$this.find('.main-menu-ul .li-menu-item-0')
    this.liLevel2 = this.$this.find('.main-menu-ul .main-menu-dropdown li>a')
    this.isOpenMenu = 'is-open-menu'
    this.isOpenMenuChild = 'open-menu-child'
    this.isOpenChild = 'is-open-child'
    this.$linksInMenu = $('.header a:not(.skip-link), .header button')
    this.$firstLinkInMenu = this.$linksInMenu.first()
    this.$lastLinkInMenu = this.$linksInMenu.last()
    this.$skipLink = $('.header a.skip-link')
    this.ariaExpanded = 'aria-expanded'
    this.classHover = 'hovering'
    this.$templateMenu = $('.header .menu-item')
    this.openMainMenuCls = 'open-main-menu'
  }

  init() {
    if (this.$this.length) {
      this.openMainMenu()
      this.clickArrowOpenDropdownMenuLevel1()
      this.clickLiOpenDropdownMenuLevel1()
      this.clickOutSite()
      this.toggleTabIndexSkipLink()
      this.hoverOpenMenu()
      this.activeTemplateMenu()
    }
  }

  /* micro function */
  microOpenCloseLevel1(currentElement, openClass, isLiLv1 = false) {
    const ele = currentElement.currentTarget
    const eleParent = $(ele).parents('.li-menu-item-0')
    if ($(window).width() < 992) {
      if (eleParent.find('.sub-menu').length && !eleParent.hasClass(openClass)) {
        this.liLevel1.removeClass(openClass)
        this.liLevel1.find('> .menu-item-link').each((_i, el) => {
          if ($(el).attr(this.ariaExpanded)) {
            $(el).attr(this.ariaExpanded, false)
          }
        })
        eleParent.addClass(openClass)
        $(ele).attr(this.ariaExpanded, true)
        if (isLiLv1) {
          return false
        }
      } else {
        eleParent.removeClass(openClass)
        $(ele).attr(this.ariaExpanded, false)
      }
    }
    return true
  }
  /* end micro */

  lazyLoadImageMenu() {
    this.$this.find(`.hovering, .${this.isOpenMenu}`).find('.lazy-header').each((_i, el) => {
      const $el = $(el)
      const src = $el.attr('data-src')
      if (src) {
        $el.attr('src', src).removeAttr('data-src').removeClass('lazy-header').addClass('b-loaded')
      }
    })
  }

  openMainMenu() {
    this.$header.on('click', '.hamburger-menu', (e) => {
      const ele = e.currentTarget

      if ($(ele).hasClass(this.isOpenMenu)) {
        $(this.elementItem).removeClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Open menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, false)
        this.$skipLink.removeAttr('tabindex')
      } else {
        $(this.elementItem).addClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Close menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, true)
        this.bindEventFocusOnMenu()
      }
      this.lazyLoadImageMenu()
    })
  }

  toggleTabIndexSkipLink() {
    $(window).on('resize', () => {
      if ($(window).outerWidth() < 992) {
        this.$skipLink.removeAttr('tabindex')
      }
    })
  }

  bindEventFocusOnMenu() {
    this.$skipLink.attr('tabindex', -1)
    this.$firstLinkInMenu.get(0).addEventListener('keydown', this.shiftTabFocusToLast.bind(this))
    this.$lastLinkInMenu.get(0).addEventListener('keydown', this.tabFocusToFirst.bind(this))
  }

  tabFocusToFirst(e) {
    if (e.which === 9 && $(window).outerWidth() < 992 && !e.shiftKey) {
      e.preventDefault()
      this.$firstLinkInMenu.focus()
    }
  }

  shiftTabFocusToLast(e) {
    if (e.which === 9 && $(window).outerWidth() < 992 && e.shiftKey) {
      e.preventDefault()
      this.$lastLinkInMenu.focus()
    }
  }

  clickArrowOpenDropdownMenuLevel1() {
    this.liLevel1.on('click', '.arrows-menu', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.microOpenCloseLevel1(e, this.isOpenChild)
    })
  }

  clickLiOpenDropdownMenuLevel1() {
    this.liLevel1.on('click', 'div.sub-menu-item-0', (e) => {
      if ($(window).width() < 1200) {
        e.preventDefault()
        e.stopPropagation()
      }
      this.microOpenCloseLevel1(e, this.isOpenChild, true)
    })
  }

  clickOutSite() {
    $(document).on('click', (event) => {
      if (!$(event.target).closest('#header.is-open-menu').length) {
        $(this.elementItem).removeClass(this.isOpenMenu)
      }
    })
  }

  closeAllSubmenu() {
    const $liHover = this.$this.find('.main-menu-ul .li-menu-item-0.hovering')
    $liHover.removeClass('hovering')
    if ($liHover.hasClass('has-sub')) {
      $liHover.find('> a').attr(this.ariaExpanded, false)
    }
  }

  hoverOpenMenu() {
    const classHoverHeader = 'pin-color'
    let timeout
    let timeHeader
    $('.li-menu-item-0').on('mouseover', (e) => {
      this.lazyLoadImageMenu()
      const liHover = this.$this.find(`.main-menu-ul .li-menu-item-0.${this.classHover}`)
      let timeDelay = 0
      if (liHover.is('.has-template-menu')) {
        timeDelay = 300
      }
      this.$header.addClass(classHoverHeader)
      if ($(window).outerWidth() > 1200 && liHover.length < 2) {
        const ele = e.currentTarget
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
          $(ele).addClass(this.classHover)
          if ($(ele).hasClass('has-sub')) {
            $(ele).find('> a').attr(this.ariaExpanded, true)
          }
        }, timeDelay)
      }
    })

    this.$mainMenu.on('mouseleave', () => {
      if ($(window).outerWidth() > 1200) {
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
        }, 50)
        timeHeader = setTimeout(() => {
          this.$header.removeClass(classHoverHeader)
          this.closeAllSubmenu()
        }, 200)
      }
    })

    $(window).on('keydown', (e) => {
      if (e.keyCode === 27 && $(window).outerWidth() > 1200) {
        clearTimeout(timeout)
        clearTimeout(timeHeader)
        timeout = setTimeout(() => {
          this.closeAllSubmenu()
        }, 50)
        timeHeader = setTimeout(() => {
          this.$header.removeClass(classHoverHeader)
          this.closeAllSubmenu()
        }, 200)
      }
    })
  }

  activeTemplateMenu () {
    this.$templateMenu.each((index, ele) => {
      const $ele = $(ele)
      if ($ele.find('.current-menu-item, .current-menu-ancestor, .active').length) {
        $ele.addClass('active')
        $ele.find('.sub-menu-item-0 ').addClass('active')
      }
    })
  }
}

new Menu().init()
