import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export default class ModImageSlider {
  constructor($el) {
    this.$this = $($el)
    this.$splide = this.$this.find('.splide')
    this.splide = null
    this.speed = 2
    this.speedHover = 1
  }

  init() {
    if (this.$this.length) {
      this.addSlider()
      const debouncedResize = this.debounce(() => {
        this.addSlider()
      }, 250)
      $(window).on('resize', debouncedResize)
    }
  }

  addSlider() {
    const splideItems = this.$splide.find('.splide__slide:not(.splide__slide--clone)').length
    this.updateSpeed()
    if (splideItems < 2) {
      this.destroySlider()
      return
    }
    if (this.$splide.hasClass('is-initialized')) {
      return
    }
    const lazyLoadObserver = this.createLazyLoadObserver()
    this.initializeSlider(lazyLoadObserver)
  }

  updateSpeed() {
    if (window.innerWidth < 768) {
      this.speed = 1.5
      this.speedHover = 1
    } else {
      this.speed = 2
      this.speedHover = 1
    }
  }

  destroySlider() {
    if (this.splide) {
      this.splide.destroy(true)
      this.$splide.removeClass('is-initialized')
      this.splide = null
    }
  }

  createLazyLoadObserver() {
    return new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src
          img.classList.remove('splide-lazy')
          observer.unobserve(img)
        }
      })
    })
  }

  initializeSlider(lazyLoadObserver) {
    const splideConfig = {
      type: 'loop',
      drag: false,
      perPage: 1,
      pagination: false,
      autoWidth: true,
      autoHeight: true,
      arrows: false,
      autoScroll: {
        speed: this.speed,
        pauseOnHover: false,
      },
    }
    this.splide = new Splide(this.$splide[0], splideConfig)
    this.splide.on('mounted', () => {
      requestAnimationFrame(() => {
        const $slides = this.$this.find('.splide__slide')
        $slides.each((_i, e) => {
          const img = e.querySelector('.splide-lazy')
          if (img) {
            lazyLoadObserver.observe(img)
          }
        })
      })
    })
    this.splide.mount({ AutoScroll })
    this.handleChangeSpeed('mouseover', this.speedHover)
    this.handleChangeSpeed('mouseout', this.speed)
  }

  handleChangeSpeed(event, speed) {
    this.$splide.on(event, () => {
      this.splide.options.autoScroll.speed = speed
      this.splide.emit('updated')
    })
  }

  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
}
if ($('.mod-image-slider').length) {
  $('.mod-image-slider').each((_i, el) => {
    new ModImageSlider(el).init()
  })
}
